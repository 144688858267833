import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const CardAppPlatformsContainer = styled.div`
  width: 100%;
  max-width: 100vh;
`;

export const CardAppPlatformsContent = styled.div`
  display: grid;
  align-items: center;
  justify-items: center;
  grid-template-columns: repeat(2, 1fr);
  gap: 3vw;
`;

export const AppPlatformsLink = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 10rem;
  max-height: 5rem;
  border-radius: 0.5rem;
  background-color: ${({ theme }) => theme.colors.gray.opacity.veryLow};
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: scale(1.1);
  }
  > svg {
    width: 38%;
  }

  > div h4 {
    font-size: ${({ theme }) => theme.fonts.size.subtitle.tiny}px;
  }
`;
