// ./src/services/storage/contract/newContractStorageService.ts

import { ConfigStorage } from '@config/index';
import DiscountCoupons from '@models/DiscountCoupons';
import Product from '@models/Product';

interface ICart extends Product {
  cart_id?: number;
}

interface IStepsSale {
  id: any;
  field_type: string;
  query: any;
}

interface IPayment {
  due_date: string;
  form_of_payment: string;
  cycle: string;
  discount_type: string;
  discount_amount_installments: number;
  number_installments_with_discount: number;
  installments: number;
  credit_card?: {
    ownership_type?: string | null;
    name?: string | null;
    number_card?: string | null;
    cvv?: string | null;
    validity?: string | null;
  };
  token?: any;
}

interface IVehicle extends IStepsSale {
  vehicle_id?: number;
}

interface IFormVehicle {
  vehicle_id?: number;
  brand: string;
  model: string;
  license_plate: string;
  year: string;
  color: string;
}

const updateCart = (data: Product): void => {
  let cart: ICart[] = getCart();
  cart = [...cart, data];
  sessionStorage.setItem(
    ConfigStorage.REBOX_SALE_NEW_CART,
    JSON.stringify(cart),
  );
};

const removeCart = (id: number): void => {
  const storageCart = getCart();
  const updatedCart = storageCart.filter((_, index) => index !== id);
  sessionStorage.setItem(
    ConfigStorage.REBOX_SALE_NEW_CART,
    JSON.stringify(updatedCart),
  );
};

const getCart = (): ICart[] => {
  const data = sessionStorage.getItem(ConfigStorage.REBOX_SALE_NEW_CART) || '';
  if (data) {
    const product: ICart[] = JSON.parse(data);
    return product;
  }

  return [] as ICart[];
};

const updateProduct = (data: IStepsSale): void => {
  sessionStorage.setItem(
    ConfigStorage.REBOX_SALE_NEW_PRODUCT,
    JSON.stringify(data),
  );
};

const getProduct = (): IStepsSale => {
  const data =
    sessionStorage.getItem(ConfigStorage.REBOX_SALE_NEW_PRODUCT) || '';
  if (data) {
    const product: IStepsSale = JSON.parse(data);
    return product;
  }

  return {} as IStepsSale;
};

// const removeProduct = (id: number): void => {
//   const storageProduct = getProduct();
//   const updatedProduct = storageProduct.filter((_, index) => index !== id);

//   sessionStorage.setItem(
//     ConfigStorage.REBOX_SALE_NEW_PRODUCT,
//     JSON.stringify(updatedProduct),
//   );
// };

const updateCustomer = (data: IStepsSale): void => {
  sessionStorage.setItem(
    ConfigStorage.REBOX_SALE_NEW_CUSTOMER,
    JSON.stringify(data),
  );
};

const getCustomer = (): IStepsSale => {
  const data =
    sessionStorage.getItem(ConfigStorage.REBOX_SALE_NEW_CUSTOMER) || '';
  if (data) {
    const customer: IStepsSale = JSON.parse(data);
    return customer;
  }

  return {} as IStepsSale;
};

const updateVehicle = (data: IStepsSale): void => {
  let vehicles: IVehicle[] = getVehicle();
  const mappedId = vehicles.map(vehicle => vehicle.id);
  if (!mappedId.includes(data.id)) {
    vehicles = [...vehicles, data];
  }
  sessionStorage.setItem(
    ConfigStorage.REBOX_SALE_NEW_VEHICLE,
    JSON.stringify(vehicles),
  );
};

const getVehicle = (): IStepsSale[] => {
  const data =
    sessionStorage.getItem(ConfigStorage.REBOX_SALE_NEW_VEHICLE) || '';
  if (data) {
    const customer: IStepsSale[] = JSON.parse(data);
    return customer;
  }

  return [] as IStepsSale[];
};

const updatePayment = (data: IPayment): void => {
  sessionStorage.setItem(
    ConfigStorage.REBOX_SALE_NEW_PAYMENT,
    JSON.stringify(data),
  );
};

const getPayment = (): IPayment => {
  const data =
    sessionStorage.getItem(ConfigStorage.REBOX_SALE_NEW_PAYMENT) || '';
  if (data) {
    const customer: IPayment = JSON.parse(data);
    return customer;
  }

  return {} as IPayment;
};

const updateFormVehicle = (data: IFormVehicle[]): void => {
  sessionStorage.setItem(
    ConfigStorage.REBOX_SALE_NEW_FORM_VEHICLE,
    JSON.stringify(data),
  );
};

const getFormVehicle = (): IFormVehicle[] => {
  const data =
    sessionStorage.getItem(ConfigStorage.REBOX_SALE_NEW_FORM_VEHICLE) || '';
  if (data) {
    const formVehicle: IFormVehicle[] = JSON.parse(data);
    return formVehicle;
  }
  return [] as IFormVehicle[];
};

const removeFormVehicle = (id: number): void => {
  const storageFormVehicle = getFormVehicle();
  const updatedFormVehicle = storageFormVehicle.filter(
    (_, index) => index !== id,
  );

  sessionStorage.setItem(
    ConfigStorage.REBOX_SALE_NEW_FORM_VEHICLE,
    JSON.stringify(updatedFormVehicle),
  );
};

const updateModelFormVehicle = (data: any): void => {
  sessionStorage.setItem(
    ConfigStorage.REBOX_SALE_NEW_MODEL_FORM_VEHICLE,
    JSON.stringify(data),
  );
};

const getModelFormVehicle = (): any => {
  const data = sessionStorage.getItem(
    ConfigStorage.REBOX_SALE_NEW_MODEL_FORM_VEHICLE,
  );
  if (data) {
    const modelFormVehicle = JSON.parse(data);
    return modelFormVehicle;
  }
  return [];
};

const updateBrandFormVehicle = (data: any): void => {
  sessionStorage.setItem(
    ConfigStorage.REBOX_SALE_NEW_BRAND_FORM_VEHICLE,
    JSON.stringify(data),
  );
};

const getBrandFormVehicle = (): any => {
  const data = sessionStorage.getItem(
    ConfigStorage.REBOX_SALE_NEW_BRAND_FORM_VEHICLE,
  );
  if (data) {
    const brandFormVehicle = JSON.parse(data);
    return brandFormVehicle;
  }
  return [];
};

const removeBrandFormVehicle = (id: number): void => {
  const storageBrandFormVehicle = getBrandFormVehicle();
  const updatedBrandFormVehicle = storageBrandFormVehicle.filter(
    (_: any, index: number) => index !== id,
  );

  sessionStorage.setItem(
    ConfigStorage.REBOX_SALE_NEW_BRAND_FORM_VEHICLE,
    JSON.stringify(updatedBrandFormVehicle),
  );
};

const updateClassificationFormVehicle = (data: any): void => {
  sessionStorage.setItem(
    ConfigStorage.REBOX_SALE_NEW_CLASSIFICATION_FORM_VEHICLE,
    JSON.stringify(data),
  );
};

const getClassificationFormVehicle = (): any => {
  const data = sessionStorage.getItem(
    ConfigStorage.REBOX_SALE_NEW_CLASSIFICATION_FORM_VEHICLE,
  );
  if (data) {
    const classificationFormVehicle = JSON.parse(data);
    return classificationFormVehicle;
  }
  return [];
};

const cleanMany = (
  untilWhatStep: 'all' | 'customer' | 'vehicle' | 'payment',
): void => {
  const cleanOptions = {
    all: () => {
      sessionStorage.removeItem(ConfigStorage.REBOX_SALE_NEW_CART);
      sessionStorage.removeItem(ConfigStorage.REBOX_SALE_NEW_PRODUCT);
      sessionStorage.removeItem(ConfigStorage.REBOX_SALE_NEW_CUSTOMER);
      sessionStorage.removeItem(ConfigStorage.REBOX_SALE_NEW_VEHICLE);
      sessionStorage.removeItem(ConfigStorage.REBOX_SALE_NEW_PAYMENT);
      sessionStorage.removeItem(ConfigStorage.REBOX_SALE_NEW_FORM_VEHICLE);
      sessionStorage.removeItem(
        ConfigStorage.REBOX_SALE_NEW_MODEL_FORM_VEHICLE,
      );
      sessionStorage.removeItem(
        ConfigStorage.REBOX_SALE_NEW_BRAND_FORM_VEHICLE,
      );
      sessionStorage.removeItem(
        ConfigStorage.REBOX_SALE_NEW_CLASSIFICATION_FORM_VEHICLE,
      );
    },
    customer: () => {
      sessionStorage.removeItem(ConfigStorage.REBOX_SALE_NEW_CUSTOMER);
      sessionStorage.removeItem(ConfigStorage.REBOX_SALE_NEW_VEHICLE);
      sessionStorage.removeItem(ConfigStorage.REBOX_SALE_NEW_PAYMENT);
    },
    vehicle: () => {
      sessionStorage.removeItem(ConfigStorage.REBOX_SALE_NEW_VEHICLE);
      sessionStorage.removeItem(ConfigStorage.REBOX_SALE_NEW_PAYMENT);
      sessionStorage.removeItem(ConfigStorage.REBOX_SALE_NEW_FORM_VEHICLE);
      sessionStorage.removeItem(
        ConfigStorage.REBOX_SALE_NEW_MODEL_FORM_VEHICLE,
      );
    },
    payment: () => {
      sessionStorage.removeItem(ConfigStorage.REBOX_SALE_NEW_PAYMENT);
    },
  };
  cleanOptions[untilWhatStep]();
};

const updateDiscountCoupons = (data: DiscountCoupons): void => {
  sessionStorage.setItem(
    ConfigStorage.REBOX_SALE_DISCOUNT_COUPON,
    JSON.stringify(data),
  );
};

const getDiscountCoupons = (): DiscountCoupons => {
  const data =
    sessionStorage.getItem(ConfigStorage.REBOX_SALE_DISCOUNT_COUPON) || '';
  if (data) {
    const discountCoupon: DiscountCoupons = JSON.parse(data);
    return discountCoupon;
  }

  return {} as DiscountCoupons;
};

export default {
  updateCart,
  getCart,
  updateProduct,
  getProduct,
  updateCustomer,
  getCustomer,
  updateVehicle,
  getVehicle,
  updatePayment,
  updateFormVehicle,
  getFormVehicle,
  getModelFormVehicle,
  updateModelFormVehicle,
  getBrandFormVehicle,
  updateBrandFormVehicle,
  updateClassificationFormVehicle,
  getClassificationFormVehicle,
  getPayment,
  removeCart,
  // removeProduct,
  removeFormVehicle,
  removeBrandFormVehicle,
  getDiscountCoupons,
  updateDiscountCoupons,
  cleanMany,
};
