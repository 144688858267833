/* eslint-disable no-await-in-loop */
// ./src/components/forms/FormVehicleNew/index.tsx
import React, { useRef, useState, useCallback, useEffect } from 'react';

import { FormHandles } from '@unform/core';
import { IoClose, IoCubeOutline } from 'react-icons/io5';
import * as Yup from 'yup';

import {
  SubtitleSecondary,
  Paragraph,
  InputSelect,
  InputText,
  InputMask,
  ButtonMain,
  ButtonOutline,
  ButtonDefault,
  CardSimpleIndicatorSelectable,
} from '@components/index';
// Importações internas
import { ConfigLabel, ConfigStyles, ConfigValues } from '@config/index';
import Product from '@models/Product';
import { apiRebox, newContractStorageService } from '@services/index';
import { getValidationErrors } from '@utils/errors';
import { formatText } from '@utils/formatters';
import { toastify } from '@utils/notifiers';

import { schemaVehicle } from './schemaValidation';
import { ISelect, IFormVehicle } from './typing';

import {
  Container,
  FormVehicle,
  ProductModal,
  Sections,
  SectionsGroup,
  SectionsItem,
  SectionsPlansOptions,
  SectionsPlansOptionsGroup,
  SectionsPlansOptionsItem,
  SectionsPlansOptionsItemGroup,
  SliderContainer,
} from './styles';

interface IProps {
  forNewSale?: {
    advanceStep(): void;
  };
  userId: string;
}

const FormVehicleNew: React.FC<IProps> = ({ userId, forNewSale }) => {
  const formRef = useRef<FormHandles>(null);

  const [loading, setLoading] = useState<boolean>(false);
  const [isOpenProductModal, setIsOpenProductModal] = useState<boolean>(false);
  const [products, setProducts] = useState<Product[]>([]);
  const [classificationForm, setClassificationForm] = useState<any[]>([]);
  const [brandsForm, setBrandsForm] = useState<any[]>([]);
  const [modelFormVehicle, setModelFormVehicle] = useState<any[]>([]);
  const [formVehicles, setFormVehicles] = useState<any[]>([
    {
      vehicle_id: 1,
      classification: '',
      brand: '',
      model: '',
      license_plate: '',
      color: '',
      year: '',
      status: 'ACTIVE',
    },
  ]);

  const getProducts = useCallback(async () => {
    try {
      const { data: response } = await apiRebox.get(
        `/products?type=${ConfigValues.rebox.product.type.plan}`,
      );
      setProducts(response.data);
    } catch (error) {
      toastify(
        `Houve um error ao buscar lista de opções de produtos.`,
        'error',
      );
    }
  }, []);

  const getClassifications = useCallback(async () => {
    try {
      const cart = newContractStorageService.getCart();

      const classificationsTypes = [];

      for (let index = 0; index < cart.length; index++) {
        const { data: responseCarClassifications } = await apiRebox.get(
          `/cars`,
        );
        classificationsTypes.push(responseCarClassifications.data);
      }
      setClassificationForm(classificationsTypes);
      newContractStorageService.updateClassificationFormVehicle(
        classificationsTypes,
      );
    } catch (error) {
      toastify(
        'Ops, não conseguimos buscar as classificações de veículos existentes.',
        'error',
      );
    }
  }, []);

  const getBrands = useCallback(async (value: string, index: number) => {
    try {
      const { data: responseCarBrands } = await apiRebox.get(
        `/cars?classification=${value}`,
      );
      const handler: any[] = [];
      handler.push(responseCarBrands.data);
      setBrandsForm(handler);

      newContractStorageService.updateBrandFormVehicle(handler);
    } catch (error) {
      toastify(
        'Ops, não conseguimos buscar as marcas de veículos existentes.',
        'error',
      );
    }
  }, []);

  const getModels = useCallback(async (value: string, index: number) => {
    try {
      const { data: responseCarModels } = await apiRebox.get(
        `/cars?brand=${value}`,
      );
      const handler: any[] = [];
      handler.push(responseCarModels.data);
      setModelFormVehicle(handler);
      // setModelFormVehicle(prevState => {
      //   const handler = prevState;
      //   handler[index] = responseCarModels.data;
      //   return handler;
      // });
      newContractStorageService.updateModelFormVehicle(handler);
    } catch (error) {
      toastify(
        'Ops, não conseguimos buscar as modelos de veículos existentes.',
        'error',
      );
    }
  }, []);

  const handleRegisterVehicle = async (data: IFormVehicle) => {
    try {
      setLoading(prevState => !prevState);

      formRef.current?.setErrors({});

      await schemaVehicle.validate(data, {
        abortEarly: false,
      });

      const vehicles = formVehicles.map(form => ({
        ...form,
        users_id: userId,
        armored: false,
      }));

      const { data: responseVehicleCreated } = await apiRebox.post(
        `/users/vehicles`,
        vehicles,
      );

      const { header, data: vehicleCreated } = responseVehicleCreated;

      toastify(header.message, 'success');

      // Caso o cliente esteja sendo criado no momento da venda
      if (forNewSale) {
        for (const vehicle of vehicleCreated) {
          newContractStorageService.updateVehicle({
            id: vehicle.id,
            field_type: 'VEHICLE_LICENSE_PLATE',
            query: vehicle.license_plate,
          });
        }
        forNewSale.advanceStep();
      }
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        formRef.current?.setErrors(errors);

        const {
          classification,
          brand,
          model,
          license_plate,
          year,
          color,
        } = errors;

        if (classification) toastify(classification, 'error');
        if (brand) toastify(brand, 'error');
        if (model) toastify(model, 'error');
        if (license_plate) toastify(license_plate, 'error');
        if (year) toastify(year, 'error');
        if (color) toastify(color, 'error');
      } else if (error.response) toastify(error.response.data.error, 'error');
    } finally {
      setLoading(prevState => !prevState);
    }
  };

  const handleOpenModal = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setIsOpenProductModal(prev => !prev);
  };

  const handleFormVehicleValues = async (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    index: number,
  ) => {
    if (event.target.name === 'brand') {
      await getModels(event.target.value, index);
    }
    setFormVehicles(currentForm =>
      currentForm.map((currForm, formIndex) =>
        formIndex === index
          ? {
              ...currForm,
              [event.target.name]: event.target.value,
            }
          : currForm,
      ),
    );
  };

  useEffect(() => {
    getProducts();
    getClassifications();
    const storageBrands = newContractStorageService.getBrandFormVehicle();
    const storageFormVehicles = newContractStorageService.getFormVehicle();
    const storageModelFormVehicle = newContractStorageService.getModelFormVehicle();
    if (classificationForm.length !== 0) {
      setFormVehicles(storageFormVehicles);
      setBrandsForm(storageBrands);
      setModelFormVehicle(storageModelFormVehicle);
    }
  }, []);

  useEffect(() => {
    getClassifications();

    newContractStorageService.updateFormVehicle(formVehicles);
  }, [formVehicles]);

  return (
    <Container>
      <FormVehicle ref={formRef} onSubmit={handleRegisterVehicle}>
        <Sections>
          {newContractStorageService.getCart().map((_, index) => (
            <>
              <SubtitleSecondary
                nameColor="black"
                textAlign="start"
                fontSize={16}
              >{`Veículo ${index + 1}`}</SubtitleSecondary>
              <SectionsGroup>
                <SectionsItem>
                  <SubtitleSecondary
                    textAlign="start"
                    nameColor="black"
                    fontSize={14}
                  >
                    Classificação
                  </SubtitleSecondary>
                  <Paragraph
                    nameColor="black"
                    textAlign="start"
                    opacity={0.5}
                    fontSize={13}
                    style={{ marginBottom: '2vh' }}
                  >
                    Define o porte do veículo do cliente
                  </Paragraph>
                  <InputSelect
                    name="classification"
                    options={classificationForm[index]}
                    onChange={event => {
                      getBrands(event.target.value, index);
                      handleFormVehicleValues(event, index);
                    }}
                    placeholder="Selecione o porte"
                    tabIndex={1}
                  />
                </SectionsItem>

                <SectionsItem>
                  <SubtitleSecondary
                    textAlign="start"
                    nameColor="black"
                    fontSize={14}
                  >
                    Marca
                  </SubtitleSecondary>
                  <Paragraph
                    nameColor="black"
                    textAlign="start"
                    opacity={0.5}
                    fontSize={13}
                    style={{
                      marginBottom: '2vh',
                    }}
                  >
                    Empresa responsável pela fabricação do veículo
                  </Paragraph>
                  <InputSelect
                    name="brand"
                    options={brandsForm[index]}
                    onChange={event => {
                      getModels(event.target.value, index);
                      handleFormVehicleValues(event, index);
                    }}
                    placeholder="Selecione a marca"
                    tabIndex={2}
                  />
                </SectionsItem>
              </SectionsGroup>

              <SectionsGroup>
                <SectionsItem>
                  <SubtitleSecondary
                    textAlign="start"
                    nameColor="black"
                    fontSize={14}
                  >
                    Modelo
                  </SubtitleSecondary>
                  <Paragraph
                    nameColor="black"
                    textAlign="start"
                    opacity={0.5}
                    fontSize={13}
                    style={{ marginBottom: '2vh' }}
                  >
                    Define a linha de fabricação do veículo
                  </Paragraph>
                  <InputSelect
                    name="model"
                    options={modelFormVehicle[index]}
                    onChange={event => {
                      handleFormVehicleValues(event, index);
                    }}
                    placeholder="Selecione o modelo"
                    tabIndex={3}
                  />
                </SectionsItem>

                <SectionsItem>
                  <SubtitleSecondary
                    textAlign="start"
                    nameColor="black"
                    fontSize={14}
                  >
                    Placa
                  </SubtitleSecondary>
                  <Paragraph
                    nameColor="black"
                    textAlign="start"
                    opacity={0.5}
                    fontSize={13}
                    style={{ marginBottom: '2vh' }}
                  >
                    Registro identificador do veículo no país
                  </Paragraph>
                  <InputMask
                    name="license_plate"
                    mask="aaa-9*99"
                    placeholder="Digite a placa"
                    onChange={event => {
                      handleFormVehicleValues(event, index);
                    }}
                    style={{ textTransform: 'uppercase' }}
                    tabIndex={4}
                  />
                </SectionsItem>
              </SectionsGroup>

              <SectionsGroup>
                <SectionsItem>
                  <SubtitleSecondary
                    textAlign="start"
                    nameColor="black"
                    fontSize={14}
                  >
                    Cor
                  </SubtitleSecondary>
                  <Paragraph
                    nameColor="black"
                    textAlign="start"
                    opacity={0.5}
                    fontSize={13}
                    style={{ marginBottom: '2vh' }}
                  >
                    Define a cor do veículo do cliente
                  </Paragraph>
                  <InputSelect
                    name="color"
                    options={ConfigLabel.rebox.others.vehicle.color}
                    placeholder="Selecione a cor"
                    onChange={event => {
                      handleFormVehicleValues(event, index);
                    }}
                    tabIndex={5}
                  />
                </SectionsItem>

                <SectionsItem>
                  <SubtitleSecondary
                    textAlign="start"
                    nameColor="black"
                    fontSize={14}
                  >
                    Ano
                  </SubtitleSecondary>
                  <Paragraph
                    nameColor="black"
                    textAlign="start"
                    opacity={0.5}
                    fontSize={13}
                    style={{ marginBottom: '2vh' }}
                  >
                    Informe o ano do modelo ou de fabricação do carro
                  </Paragraph>
                  <InputText
                    name="year"
                    type="number"
                    onChange={event => {
                      handleFormVehicleValues(event, index);
                    }}
                    placeholder="Digite o ano"
                    min={0}
                    tabIndex={6}
                  />
                </SectionsItem>
              </SectionsGroup>
            </>
          ))}

          <SectionsGroup>
            <SectionsItem>
              <SubtitleSecondary
                textAlign="start"
                nameColor="black"
                fontSize={14}
                style={{ marginBottom: '1vh' }}
              >
                Adicione um novo plano
              </SubtitleSecondary>
              <Paragraph
                textAlign="start"
                nameColor="black"
                opacity={0.8}
                fontWeight={500}
              >
                Você deseja contratar mais de um plano para outro veículo?
              </Paragraph>
              <ButtonOutline
                style={{
                  marginTop: '1rem',
                  maxWidth: '200px',
                }}
                onClick={handleOpenModal}
              >
                Adicionar
              </ButtonOutline>
            </SectionsItem>
          </SectionsGroup>
          <ButtonMain
            type="submit"
            loading={loading}
            style={{ marginTop: '4vh', maxWidth: 250 }}
            tabIndex={8}
          >
            Cadastrar
          </ButtonMain>
        </Sections>
      </FormVehicle>
      <ProductModal
        isOpen={isOpenProductModal}
        ariaHideApp={false}
        onRequestClose={() => setIsOpenProductModal(prev => !prev)}
        contentLabel="ProductsModal"
      >
        <ButtonDefault
          iconLeft={IoClose}
          style={{
            position: 'absolute',
            top: 10,
            right: 10,
            maxWidth: 50,
            padding: 0,
          }}
          onClick={() => setIsOpenProductModal(prev => !prev)}
        />
        <SectionsPlansOptionsGroup>
          {products.map(product => (
            <CardSimpleIndicatorSelectable
              key={product.id}
              icon={{
                element: IoCubeOutline,
                size: 20,
                opacity: 1,
                colorDefault: ConfigStyles.rebox.colors.black.opacity.average,
                colorActive: ConfigStyles.rebox.colors.white.main,
              }}
              label={{
                text: formatText.capitalizedFirstLetter(product.name),
                colorDefault: ConfigStyles.rebox.colors.black.opacity.average,
                colorActive: ConfigStyles.rebox.colors.white.main,
              }}
              isSelected={false}
              positionContent="center"
              onClick={() => {
                newContractStorageService.updateCart(product);
                setFormVehicles((currentForm: any) => [
                  ...currentForm,
                  {
                    vehicle_id:
                      currentForm[currentForm.length - 1].vehicle_id + 1,
                    classification: '',
                    brand: '',
                    model: '',
                    license_plate: '',
                    color: '',
                    year: '',
                    status: '',
                  },
                ]);
                setIsOpenProductModal(false);
              }}
            />
          ))}
        </SectionsPlansOptionsGroup>
      </ProductModal>
    </Container>
  );
};

export default FormVehicleNew;
